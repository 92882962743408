<template>
  <div class="progress-bar" :class="[color, { horizontal }]">
    <div class="wrapper rounded-lg" :style="barWidth && `width: ${barWidth}`">
      <div
        class="bar"
        role="progressbar"
        :style="`width: ${percentageProgression}%`"
        :aria-valuenow="percentageProgression"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <p class="text-center font-weight-bold text mb-0" :class="[horizontal ? 'ml-2' : 'mt-2']">
      {{ percentageProgression }}%
    </p>
  </div>
</template>

<script>
export default {
  name: "progress-bar",
  props: {
    progression: Number,
    color: { type: String, default: "primary" },
    valuemin: { type: Number, default: 0 },
    valuemax: { type: Number, default: 1 },
    horizontal: { type: Boolean, default: false },
    mustRound: { type: Boolean, default: true },
    barWidth: { type: String, optional: true },
  },
  computed: {
    percentageProgression() {
      let progression = Math.min((100 * this.progression) / (this.valuemax - this.valuemin), 100);
      if (this.mustRound) return Math.round(progression);
      else return progression;
    },
  },
};
</script>

<style scoped>
.progress-bar {
  --color: var(--system-picsellia);

  &.secondary {
    --color: var(--secondary-system-picsellia);
  }

  &.danger {
    --color: var(--system-red);
  }

  &.horizontal {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    height: fit-content;

    > .wrapper {
      margin-block: auto;
    }
  }
}

.wrapper {
  height: 0.7rem;
  overflow: hidden;
  background-color: color-mix(in srgb, var(--color) 20%, transparent);
}

.bar {
  height: 0.7rem;
  background-color: var(--color);
  transition: width 0.3s ease;
}

.text {
  color: var(--color);
}
</style>
