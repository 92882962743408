<template>
  <IForm
    v-model="inklineSchema"
    :loading="loading"
    :color="color"
    :name="name"
    :size="size"
    :disabled="readonly"
    :inline="inline"
    :readonly="readonly"
    :validate="validate"
    @submit="onSubmit"
  >
    <div v-if="progression" class="form-progress">
      <p class="loading-text">
        <span>{{ progression.label }}</span>
        <span v-if="progression.stepCount > 1">({{ progression.stepIndex + 1 }}/{{ progression.stepCount }})</span>
      </p>
      <ProgressBar :color="submitColor" :progression="progression.value" :valuemax="progression.max" />
    </div>
    <slot v-else />
    <footer>
      <slot name="footer" :loading="loading" />
      <button v-if="modal" type="button" class="button -light -md" :disabled="loading" @click="modal.cancel">
        Cancel
      </button>
      <IButton
        v-if="!readonly"
        type="submit"
        :loading="loading"
        :color="submitColor"
        :title="submitTitle"
        :disabled="loading || readonly || !formDirty"
      >
        {{ submitLabel }}
      </IButton>
      <slot name="trailing-footer" :loading="loading" />
    </footer>
  </IForm>
</template>

<script setup>
import { computed, inject, toRaw, onMounted, ref, watch } from "vue";
import { useForm as useInklineForm } from "@inkline/inkline/composables/useForm.js";
import PicselliaToast from "@/js/general/picsellia_toast.js";
import { createSchema } from "@inkline/inkline/validation/schema/createSchema.js";
import IForm from "@inkline/inkline/components/IForm/IForm.vue";
import IButton from "@inkline/inkline/components/IButton/IButton.vue";
import ProgressBar from "@/components/loaders/ProgressBar.vue";

const props = defineProps({
  schema: { type: Object, required: true },
  action: { type: Function, required: true },
  danger: { type: Boolean, default: false },
  dirty: { type: Boolean, default: false },
  submitLabel: { type: String, default: "Submit" },
  submitColor: { type: String, default: "primary" },

  // Inkline props
  color: { type: String },
  name: { type: String },
  size: { type: String },
  inline: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  validate: { type: Boolean, default: true },
});

const modal = inject("Modal", undefined);
const progression = ref(undefined);

const _loading = ref(false);
const loading = computed({
  get: () => {
    return modal?.loading.value ?? _loading.value;
  },
  set: (value) => {
    if (modal) modal.setLoading(value);
    else _loading.value = value;
  },
});

onMounted(() => {
  reset();
});

const { schema: inklineSchema, form: inklineForm } = useInklineForm(getFormSchema());

const formDirty = computed(() => props.dirty || inklineSchema.value.dirty);
const submitTitle = computed(() => (!formDirty.value ? "No field to update" : undefined));
let unwatch = () => {};

watch(() => props.schema, onUpdateFormSchema);

function getFormSchema() {
  const schema = { ...toRaw(props.schema) };
  if (props.dirty) {
    for (const fieldName in schema) {
      schema[fieldName].dirty = props.dirty;
    }
  }
  return schema;
}

async function onSubmit() {
  try {
    unwatch();
    loading.value = true;
    const response = await props.action(inklineForm.value);
    await processResponse(response);
  } catch (error) {
    reset();
    if (error?.message) PicselliaToast.error({ message: error.message, duration: error.duration ?? 3000 });
  }
}

function reset() {
  loading.value = false;
  progression.value = undefined;
}

async function processResponse(response) {
  if (response.resend) return;
  if (response.progression) {
    progression.value = response.progression;
    const newResponse = await progression.value.promise;
    return processResponse(newResponse);
  }
  if (response.message) {
    if (response.warning) PicselliaToast.warning(response);
    else if (response.default) PicselliaToast.default(response);
    else PicselliaToast.success(response);
  }

  if (!modal && !response.redirectUrl) reset();
  if (response.redirectUrl) setTimeout(() => (location.href = response.redirectUrl), 1500);
  else if (modal) modal.hide();
}

function onUpdateFormSchema() {
  inklineSchema.value = createSchema(getFormSchema());
  inklineSchema.value.dirty = props.dirty;
  unwatch();
  unwatch = watch(
    () => inklineSchema.value.dirty,
    () => {
      if (!isDirty()) inklineSchema.value.dirty = props.dirty;
    },
    { once: true },
  );
}

function isDirty() {
  for (const fieldName in props.schema) {
    if (props.schema[fieldName].value === inklineForm.value[fieldName]) continue;
    return true;
  }
  return false;
}

defineExpose({ inklineSchema });
</script>

<style scoped lang="scss">
.form-progress {
  width: 100%;

  .loading-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.3rem;
    margin-block-end: 1rem;
  }
}
</style>
